import React from "react";
import classnames from "classnames";
import styles from "./divider.module.scss";

interface Props {
  className?: string;
}

const Divider = (props: Props) => {
  const { className } = props;
  const classes = classnames(styles.divider, className);

  return <hr className={classes} />;
};

export default Divider;
